import { IFixMe } from "#/types.ts/other";
import _ from "lodash";

export enum IOrderFufillmentType {
  curbside = "curbside",
  /** the dsp in which the order was placed, manages the delivery service */
  delivery = "delivery",
  dine_in = "dine_in",
  /**
   * if the store/merchant manages their own DoorDash drive, delivery service,
   * or has their own delivery drivers
   */
  merchant_managed_delivery = "merchant_managed_delivery",
  pickup = "pickup",
  /** stream chooses and manages the delivery of an order  */
  stream_managed_delivery = "stream_managed_delivery",
}

export const orderStateMap: OrderStateMap = {
  active: "active",
  history: "history",
  completed: "completed",
  canceled: "canceled",
  failed: "failed",
  scheduled: "scheduled",
  ["in-progress"]: "in_progress",
  ["ready-for-pickup"]: "ready_for_pickup",
  ["out-for-delivery"]: "out_for_delivery",
  ["active_failed"]: "active_failed",
};

export const ORDER_STATUSES = {
  CANCELED: "canceled",
  COMPLETED: "completed",
  FAILED: "failed",
  DSP_CANCELED: "dsp_canceled",
  IN_PROGRESS: "in_progress",
  MERCHANT_CANCELED: "merchant_canceled",
  OUT_FOR_DELIVERY: "out_for_delivery",
  READY_FOR_PICKUP: "ready_for_pickup",
  SCHEDULED: "scheduled",
  UNKNOWN: "unknown",
};

export enum OrderStatus {
  /**
   * Order in a quoted state; awaiting customer confirmation.
   * Ex. requires submitOrder from Google to move to pending
   */
  QUOTED = "quoted",
  /**
   * Order has been scheduled for a future time / not an ASAP order
   */
  SCHEDULED = "scheduled",
  /**
   * Pending order that needs some work to be accepted, but are created by the customer.
   * These orders are queued.
   */
  PENDING = "pending",
  /**
   * Order created
   */
  IN_PROGRESS = "in_progress",
  /**
   * Order is ready to be picked up by the customer, or delivery driver
   */
  READY_FOR_PICKUP = "ready_for_pickup",
  /**
   * Order is out for delivery or at the delivery dropoff point, but not completed
   */
  OUT_FOR_DELIVERY = "out_for_delivery",
  /**
   * Order has been delivered by a dsp, delivered by stream managed delivery
   * service, or picked up by customer
   */
  COMPLETED = "completed",
  /**
   * The customer/dsp has cancelled the order
   */
  DSP_CANCELED = "dsp_canceled",
  /**
   * The restaurant has cancelled the order
   */
  MERCHANT_CANCELED = "merchant_canceled",
  /**
   * Generally means it has been 24 hours and the order was still in progress for some reason
   */
  UNKNOWN = "unknown",
  /**
   * Order failed stream internal validations for menu or payment, or a crash occured
   */
  FAILED = "failed",
}

export const PREVIOUS_ORDER_STATUSES = {
  out_for_delivery: "ready_for_pickup",
  ready_for_pickup: "in_progress",
};

export const NEXT_ORDER_STATUSES = {
  in_progress: "ready_for_pickup",
  ready_for_pickup: "out_for_delivery",
  out_for_delivery: "completed",
  scheduled: "scheduled",
};

export const ORDER_TEXT = {
  canceled: "Canceled", // this is not in OrderStatus enum
  completed: "Completed",
  dsp_canceled: "Canceled",
  merchant_canceled: "Canceled",
  failed: "Failed",
  in_progress: "In progress",
  out_for_delivery: "Out for delivery",
  ready_for_pickup: "Ready for pickup",
  scheduled: "Scheduled",
  unknown: "Unknown",
};

export const ORDER_HEADER: any = {
  active: "All orders",
  all: "All",
  "in-progress": "In progress",
  "out-for-delivery": "Out for delivery",
  "ready-for-pickup": "Ready for pickup",
  scheduled: "Scheduled",
  history: "History",
  completed: "Completed",
  canceled: "Canceled",
  failed: "Failed",
};

/** @TODO fix backend swagger for order types */
export const DELIVERY_TIME = (
  order: IFixMe,
  prepTime: number,
): { timeLeft: number; timeString: string } => {
  let deliveryTimeInMinutes;
  if (order.estimated_fulfillment_time) {
    deliveryTimeInMinutes = Math.floor(
      (order.estimated_fulfillment_time - Date.now()) / 60000,
    );
  } else {
    const MINUTE_IN_MILLISECONDS = 60000;
    const PREP_TIME_IN_MILI = prepTime * MINUTE_IN_MILLISECONDS;
    deliveryTimeInMinutes = Math.floor(
      (PREP_TIME_IN_MILI + order.order_time - Date.now()) / 60000,
    );
  }

  if (deliveryTimeInMinutes <= 0) {
    return { timeLeft: 0, timeString: "0m" };
  }

  const days = Math.floor(deliveryTimeInMinutes / 1440);
  const hours = Math.floor((deliveryTimeInMinutes % 1440) / 60);
  const minutes = deliveryTimeInMinutes % 60;

  let timeString = "";
  if (days > 0) {
    timeString += `${days}d `;
  }
  if (hours > 0) {
    timeString += `${hours}hr `;
  }
  if (minutes > 0 || timeString === "") {
    timeString += `${minutes}m`;
  }

  return { timeString: timeString.trim(), timeLeft: deliveryTimeInMinutes };
};

export const PROGRESS = (order: IFixMe, prepTime: number) => {
  const { timeLeft: time } = DELIVERY_TIME(order, prepTime);
  const totalTime = prepTime;
  const currentTime = time;
  const progress = 100 - (currentTime / totalTime) * 100;
  return progress;
};

export enum OrderSortOptions {
  MOST_RECENT = "Most recent",
  LEAST_ITEMS = "Least items",
  MOST_ITEMS = "Most items",
  OLDEST = "Oldest",
  CUSTOMER_NAME = "Customer name",
}

/**
 * Translate url param to order state
 */
export type OrderStateMap = {
  active: "active";
  history: "history";
  completed: "completed";
  failed: "failed";
  canceled: "canceled";
  scheduled: "scheduled";
  ["in-progress"]: "in_progress";
  ["ready-for-pickup"]: "ready_for_pickup";
  ["out-for-delivery"]: "out_for_delivery";
  active_failed: "active_failed";
};

export const DeliverStatusStages = {
  pending: "pending",
  pending_driver_confirmation: "pending",
  enroute_to_pickup: "confirmed",
  enroute_to_dropoff: "delivery",
  arrived_at_pickup: "arrived",
  arrived_at_return: "arrived",
  arrived_at_dropoff: "complete",
  completed: "complete",
  enroute_to_return: "delivery",
  merchant_canceled: "none",
  failed_to_assign_driver: "confirmed",
  failed_to_deliver: "delivery",
  failed_to_pickup: "arrived",
  failed: "none",
  returned: "none",
};

/**
 * Delivery statuses for an order if the order is Stream managed delivery
 */
export enum DeliveryStatus {
  /**
   * In a quoted state
   */
  PENDING = "pending",
  /**
   * Pending driver to be assigned to pickup an order.
   */
  PENDING_DRIVER_CONFIRMATION = "pending_driver_confirmation",
  /**
   * Delivery driver enroute to pickup order from merchaant
   */
  ENROUTE_TO_PICKUP = "enroute_to_pickup",
  /**
   * Order return has arrived at the customer dropoff point, but has not been completed.
   */
  ARRIVED_AT_PICKUP = "arrived_at_pickup",
  /**
   * Order has been pickup by the driver and on its way to the customer
   */
  ENROUTE_TO_DROPOFF = "enroute_to_dropoff",
  /**
   * Order return has arrived at the customer dropoff point, but has not been completed.
   */
  ARRIVED_AT_DROPOFF = "arrived_at_dropoff",
  /**
   * Order has been delivered, no further action required
   */
  COMPLETED = "completed",
  /**
   * No driver has been able to be assigned to pickup an order.
   */
  FAILED_TO_ASSIGN_DRIVER = "failed_to_assign_driver",
  /**
   * Driver has failed to dropoff the order to the customer
   */
  FAILED_TO_DELIVER = "failed_to_deliver",
  /**
   * Driver has failed to pickup the order from the merchant
   */
  FAILED_TO_PICKUP = "failed_to_pickup",

  FAILED = "failed",

  /**
   * The merchant has cancelled the order.
   */
  MERCHANT_CANCELED = "merchant_canceled",

  /**
   * @NOTE Returned order management not implemented
   */

  /**
   * Order has been pickup by the driver and on its way to the restaurant
   */
  ENROUTE_TO_RETURN = "enroute_to_return",
  /**
   * Order return has arrived at the restaurant, but has not been completed.
   */
  ARRIVED_AT_RETURN = "arrived_at_return",
  /**
   * Order return to restaurant has been completed.
   */
  RETURNED = "returned",
}

export enum OrderErrorReason {
  POS_REFERENCES_INCORRECT = "Unable to resolve order item references",
  OUT_OF_RANGE = "The order cannot be delivered to the user's address",
  ITEM_NOT_IN_POS = "An item ordered is missing within the store POS.",
  ITEM_NOT_IN_STREAM = "An item ordered is missing.",
  PRICE_MISMATCH = "The price of an item has changed.",
  OUT_OF_STOCK = "Sorry, some of the items are not available right now.",
  RESTAURANT_CLOSED = "Restaurant is closed",
  UNKNOWN = "Sorry, the restaurant is unable to take your order.",
  FAILED_CREATE_ORDER = "Sorry, the restaurant cannot take your order right now.",
  NO_COURIER_AVAILABLE = "Sorry, there are no drivers available.",
  PAYMENT_DECLINED = "There is an issue with payment processing.",
  REQUIREMENTS_NOT_MET = "The minimum order requirements have not been met.",
  INVALID_PHONE_NUMBER = "Invalid phone number provided",
}

export const DEFAULT_PREP_TIME_MINS = 30;

export const determineDeliveryType = (
  fulfillment_type: IOrderFufillmentType,
) => {
  if (fulfillment_type === "merchant_managed_delivery") {
    return "Self Delivery";
  }

  return _.startCase(
    fulfillment_type.includes("delivery") ? "Delivery" : fulfillment_type,
  );
};

export enum OrderViewType {
  "kanban" = "kanban",
  "list" = "list",
}
